<div class="container-fluid" style="margin-top: 30px;padding: 5%;width: 100%;">



    <mat-accordion>
        <mat-expansion-panel hideToggle *ngFor="let a of answers">

            <mat-expansion-panel-header>
                <mat-panel-title>
                    Q.{{ answers.indexOf(a) + 1 }} => {{ a.question }}
                </mat-panel-title>
                <mat-panel-description style="float: right;" *ngIf="a.chosenAnswer">
                    <!--i *ngIf="a.corrAnswer" class='fas fa-check' style='font-size:20px;color:green'></i>-->
                    <mat-icon  *ngIf="a.corrAnswer">done</mat-icon>
                </mat-panel-description>
                <mat-panel-description style="float: right;" *ngIf="a.chosenAnswer==null">
                   <!--<i *ngIf="!a.corrAnswer && !a.imgObtMarks" class="fa" style="font-size:20px; color: rgb(224, 39, 39);margin-right:8px;margin-left: 8px;">&#xf00d;</i>-->
                   <mat-icon *ngIf="!a.corrAnswer && !a.imgObtMarks">clear</mat-icon>
                </mat-panel-description>
                <mat-panel-description style="float: right;" *ngIf="!a.corrAnswer && a.imgObtMarks" >
                   Marks = {{a.imgObtMarks}}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <ul style="list-style-type: none;">
                <!--<li *ngIf="a.imgAnswer" style="padding: 2%;"><button mat-raised-button color="primary" (click)="downloadPdf(a.imgAnswer)">Download Your Answer</button></li>-->
                <li *ngIf="a.diagram" style=" height: 250px;" class="list-group-item" class="diagram" role="listitem">Diagram: <img class="diagram" src="https://eiilmlibrary.com:8080/{{a.diagram}}"></li>
                <li *ngIf="a.option1!=null" class="list-group-item" role="listitem">{{[a.option1]}}</li>
                <li *ngIf="a.imgOpt1" class="list-group-item" role="listitem"> (i)<img class="diagram" src="https://eiilmlibrary.com:8080/{{a.imgOpt1}}"></li>
                <li *ngIf="a.option2!=null" class="list-group-item" role="listitem">{{[a.option2]}}</li>
                <li *ngIf="a.imgOpt2" class="list-group-item" role="listitem"> (i)<img class="diagram" src="https://eiilmlibrary.com:8080/{{a.imgOpt2}}"></li>
                <li *ngIf="a.option3!=null" class="list-group-item" role="listitem">{{[a.option3]}}</li>
                <li *ngIf="a.imgOpt3" class="list-group-item" role="listitem"> (i)<img class="diagram" src="https://eiilmlibrary.com:8080/{{a.imgOpt3}}"></li>
                <li *ngIf="a.option4!=null" class="list-group-item" role="listitem">{{[a.option4]}}</li>
                <li *ngIf="a.imgOpt4" class="list-group-item" role="listitem"> (i)<img class="diagram" src="https://eiilmlibrary.com:8080/{{a.imgOpt4}}"></li>
                <li *ngIf="a.answer" class="list-group-item" role="listitem">Correct Answer: option {{[a.answer]}}</li>
                <!--
                <li *ngIf="a.imgCorrAnswer"><button mat-raised-button color="warn" (click)="downloadPdf(a.imgCorrAnswer)">Download Coorect Answer</button></li>-->
            </ul>
        </mat-expansion-panel>
    </mat-accordion>
    <!--
    <mat-accordion>
        <mat-expansion-panel hideToggle *ngFor="let q of answers">

            <mat-expansion-panel-header>

                <mat-panel-title style="color: rgb(55, 69, 148);font-weight: bolder;">
                    {{ q.question | uppercase }}
                </mat-panel-title>
                <mat-panel-description style="float: right;" *ngIf="a.corrAnswer">
                    <i class='fas fa-check' style='font-size:20px;color:green'></i>
                </mat-panel-description>
                <mat-panel-description style="float: right;" *ngIf="!a.corrAnswer">
                    <i class="fa" style="font-size:20px; color: rgb(224, 39, 39);margin-right:8px;margin-left: 8px;">&#xf00d;</i>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-list role="list" class="row">
                <mat-list-item *ngIf="q.diagram" style="height: 250px;" role="listitem">
                    Diagram:
                    <img class="diagram" src="https://eiilmlibrary.com:8080/{{q.diagram}}">
                </mat-list-item>
                <mat-list-item *ngIf="q.option1" role="listitem">(i) {{[q.option1]}}</mat-list-item>
                <mat-list-item *ngIf="q.imgOpt1" class="diagram" role="listitem">
                    (i)<img class="diagram" src="https://eiilmlibrary.com:8080/{{q.imgOpt1}}">
                </mat-list-item>
                <mat-list-item *ngIf="q.option2" role="listitem">(ii) {{[q.option2]}}</mat-list-item>
                <mat-list-item *ngIf="q.imgOpt2" class="diagram" role="listitem">
                    (ii)<img class="diagram" src="https://eiilmlibrary.com:8080/{{q.imgOpt2}}">
                </mat-list-item>
                <mat-list-item *ngIf="q.option3" role="listitem">(iii) {{[q.option3]}}</mat-list-item>
                <mat-list-item *ngIf="q.imgOpt3" class="diagram" role="listitem">
                    (iii)<img class="diagram" src="https://eiilmlibrary.com:8080/{{q.imgOpt3}}">
                </mat-list-item>
                <mat-list-item *ngIf="q.option4" role="listitem">(iv) {{[q.option4]}}</mat-list-item>
                <mat-list-item *ngIf="q.imgOpt4" class="diagram" role="listitem">
                    (iv)<img class="diagram" src="https://eiilmlibrary.com:8080/{{q.imgOpt4}}">
                </mat-list-item>
                <mat-list-item *ngIf="q.imgOpt5" class="diagram" role="listitem">
                    Answer:<img class="diagram" src="https://eiilmlibrary.com:8080/{{q.imgAnswer}}">
                </mat-list-item>
                <mat-list-item *ngIf="q.answer" role="listitem">Answer : {{[q.answer]}}</mat-list-item>
            </mat-list>
        </mat-expansion-panel>
    </mat-accordion>
-->
</div>