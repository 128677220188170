import { Component, OnInit } from '@angular/core';
import { Service } from 'src/app/service/REST';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { InstructionSetComponent } from './instruction-set/instruction-set.component';
import { QuestionOverviewComponent } from './question-overview/question-overview.component';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd,ActivatedRoute} from '@angular/router';
import {MatRadioModule} from '@angular/material/radio';
import {FormControl} from '@angular/forms';
import * as jsPDF from 'jspdf';



@Component({
  selector: 'app-student-view-paper',
  templateUrl: './student-view-paper.component.html',
  styleUrls: ['./student-view-paper.component.css']
})
export class StudentViewPaperComponent implements OnInit {
  paper: any;
  i = 0;
  j = 0;
  exam_ended=0;
  question: any;
  dataDisplay: any;
  UserById: any;
  studDash: any;
  studId: any;
  check = true;
  timer: number;
  countdownSub: Subscription;
  url: string;
  urlSafe: SafeResourceUrl;
  recording: boolean;
  descAns: any;
  descPDF:any;
  descPDFquest=[];
  descriptiveInput : any;
  descriptiveAnswerInput: any;
  


  constructor(public service: Service, public router: Router, public sanitizer: DomSanitizer, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.recording = true;
    console.log("NgPass");
    
    
    
  
    
   

    this.checkToken();
    const countdown = interval(60000);
    this.countdownSub = countdown.subscribe(res => {
      if (this.timer > 0) {
        this.timer = this.timer - 1 ;
        this.service.timerUpdate({id: this.UserById, timer: this.timer }).subscribe((studDash: any) => {
        // console.log(studDash.testTimer);
        if (studDash.testTimer <= 0 ) {
          alert('exam finished');
          this.finish();
        }
      });
        if (this.timer === 0 ) {
          this.countdownSub.unsubscribe();
      }

      }

    });

  }
  



  uploadPdf(evt) { 
    console.log("uploadPass");
    
    this.service.uploadAnswerSingle(evt.target.files[0]).subscribe(p => {
      console.log("uploadpdf",p.path);
      // this.question[this.i].imgAnswer = p.path;
      alert(p.path)
      this.descPDF = p.path;
      alert(p+ 'uploaded'); },
      e => {
        alert('failed');
        console.log("upload pdf 2",e) ;} )
    
  }



  /*checkDescIn() {
    console.log("before");
    console.log(this.dataDisplay.imgAnswer);
    if (this.dataDisplay.imgAnswer!=null) {
      this.dataDisplay.imgAnswer = this.descAns;
      console.log("checkdesc",this.descAns)
    } else {
      console.log("checkdescn else",this.descAns)
    }
  }*/
    
    
  





  checkOption1() {
    console.log("checkOptionPass");
    if ((this.question[this.i].answer == this.dataDisplay.imgAnswer) && (this.dataDisplay.imgAnswer == 1)){
      this.question[this.i].checkoption1 = true;
      this.question[this.i].corrAnswer = 1;
      this.question[this.i].chosenAnswer = 1;
    }
     else if ((this.question[this.i].answer == this.dataDisplay.imgAnswer) && (this.dataDisplay.imgAnswer == 2))  {
      this.question[this.i].checkoption2 = true;
      this.question[this.i].corrAnswer = 2;
      this.question[this.i].chosenAnswer = 2;

   }
   else if ((this.question[this.i].answer == this.dataDisplay.imgAnswer) && (this.dataDisplay.imgAnswer == 3)) {
    this.question[this.i].checkoption3 = true;
    this.question[this.i].corrAnswer = 3;
    this.question[this.i].chosenAnswer = 3;
   }
   else if ((this.question[this.i].answer == this.dataDisplay.imgAnswer) && (this.dataDisplay.imgAnswer == 4)) {
    this.question[this.i].checkoption4 = true;
    this.question[this.i].corrAnswerr = 4;
    this.question[this.i].chosenAnswer = 4;
   }
   else{
    this.question[this.i].checkoption1 = false;
    this.question[this.i].chosenAnswer = null;
    this.question[this.i].corrAnswerr = false;
    this.question[this.i].checkoption2 = false;
   
    this.question[this.i].checkoption3 = false;
    
    this.question[this.i].checkoption4 = false;
      //switch case

   }
  }
 
 
  
  
  /*checkImgOption1() {
    if (!this.question[this.i].checkimgOpt1) {
      this.question[this.i].checkimgOpt1 = true;
      this.question[this.i].chosenAnswer = 1;
    } else {
      this.question[this.i].checkimgOpt1 = false;
      this.question[this.i].chosenAnswer = null;
    }

  }
  checkImgOption2() {
    if (!this.question[this.i].checkimgOpt2) {
      this.question[this.i].checkimgOpt2 = true;
      this.question[this.i].chosenAnswer = 2;
    } else {
      this.question[this.i].checkimgOpt2 = false;
      this.question[this.i].chosenAnswer = null;
    }

  }
  checkImgOption3() {
    if (!this.question[this.i].checkimgOpt3) {
      this.question[this.i].checkimgOpt3 = true;
      this.question[this.i].chosenAnswer = 3;
    } else {
      this.question[this.i].checkimgOpt3 = false;
      this.question[this.i].chosenAnswer = null;
    }

  }
  checkImgOption4() {
    if (!this.question[this.i].checkimgOpt4) {
      this.question[this.i].checkimgOpt4 = true;
      this.question[this.i].chosenAnswer = 4;
    } else {
      this.question[this.i].checkimgOpt4 = false;
      this.question[this.i].chosenAnswer = null;
    }

  }*/


  prev(){
    this.j =1;
    this.next();
    console.log("prevPass");
    console.log("The value of i");
    console.log(this.i);
    
    /*this.checkDescIn();*/
    console.log("yeyeye")
    this.checkOption1();
    this.paper.testPapers[this.i].imgAnswer=this.dataDisplay.imgAnswer;
    

    console.log(this.question);
    this.i = this.i - 1;
    if(this.paper.descType === 'input')
    {
    this.descriptiveInput =  this.question[this.i].descriptiveAnswerInput;
    console.log("The value of i");
    console.log(this.i);
    }
    this.dataDisplay = this.paper.testPapers[this.i];
    this.descAns = null;
 
   

    
    this.router.navigate([this.router.url])
   
   
 
    
  }
  next(){
    console.log("nextPass");
    /*this.checkDescIn();*/
    console.log("The value of i");
    console.log(this.i);
   
    if(!this.paper.desc)
    {
    this.checkOption1();
    }
    this.paper.testPapers[this.i].imgAnswer=this.dataDisplay.imgAnswer;
    console.log(this.paper.testPapers.length);
    if(this.paper.descType === 'input')
    {
    
    this.descPDFquest[this.i]=this.dataDisplay.question;
    this.question[this.i].descriptiveAnswerInput = this.descriptiveInput;
    console.log("The question set is");
    console.log(this.descPDFquest);
    console.log("The value of i");
    console.log(this.i);
    console.log("The answer set is");
    console.log( this.question[this.i].descriptiveAnswerInput);
    
    
    }

    if(this.i < this.paper.testPapers.length && this.j!=1)
    {this.i = this.i + 1;}
    if(!this.question[this.i].descriptiveAnswerInput)
    {
    this.descriptiveInput=null;
    }
    else{
      this.descriptiveInput =  this.question[this.i].descriptiveAnswerInput;
    }
    this.j=0;

    
    this.dataDisplay = this.paper.testPapers[this.i];
    console.log("data display");
    console.log(this.dataDisplay);
    console.log(this.paper.testPapers);
    console.log("question");
    console.log(this.question);
 

    this.descAns = null;
    this.router.navigate([this.router.url])
    
    
  }
  goto( n: number){
    console.log("gotoPass");
    this.j=1;
    this.next()
    this.paper.testPapers[this.i].imgAnswer=this.dataDisplay.imgAnswer;
    console.log("The value of i");
    console.log(this.i);
   

    this.checkOption1();
    this.i = n;
    if(this.paper.descType === 'input')
    {
     this.descriptiveInput =  this.question[this.i].descriptiveAnswerInput;
     console.log("The value of i");
    console.log(this.i);
    }
   
    
    

    this.dataDisplay = this.paper.testPapers[this.i];
    this.dataDisplay.diagram =this.paper.testPapers[this.i].file;
    this.descAns = null;
    console.log("data display");
    console.log(this.dataDisplay);
    console.log("URL")
    this.router.navigate([this.router.url])
    console.log("URL")
    console.log(this.router.url)
    
  }
  choose( n: number){
    console.log("chosePass");
    this.question[this.i][5] = '' + n + ' ';
  }
  checkToken() {
    console.log("checkPass");
    if (! (JSON.parse(localStorage.getItem('studentId')))) {
      this.UserById = JSON.parse(localStorage.getItem('studentId'));

    } else {
      this.UserById = JSON.parse(localStorage.getItem('studentId'));
      // tslint:disable-next-line:max-line-length
      this.service.getStudDash({id: this.UserById}).subscribe((s: any) => {
        this.studDash = s; this.studId = s._id; this.ViewPaper(s);
        this.timer = s.testTimer;
        this.url = s.recordingLink;
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      });
  }
  }

  ViewPaper(student) {
    console.log("viewPass");
    this.paper = this.service.viewPaper;
    console.log(this.paper);
    // tslint:disable-next-line:max-line-length

    this.question = student.liveTest ;
    this.dataDisplay = this.question[this.i];
    this.dataDisplay.diagram =this.paper.testPapers[this.i].file
    // this.question = this.service.viewPaper.testPapers;
    console.log("view paper",this.paper);
   console.log(this.paper.testPapers.length);
   
  }
  
  finish() {
    if(this.exam_ended==0)
    {
      this.exam_ended=1;
    
    
   

    if (!this.paper.desc) {
      

     

      var testdata={
        sheet : this.question,
        paper: this.paper,
        studentName: this.studDash.name,
        studentId: this.studId,
        studentRoll: this.studDash.Id_Roll,
        descPDF: this.descPDF,
        instruction: this.paper.instruction,
        videoFileName: this.studDash.name + this.studDash._id + this.paper._id
      };
      var ans_paper=JSON.stringify(testdata)
      let file = new Blob([ans_paper], {type: '.txt'});
     

      
    
   
     //this.service.uploadfile(file).subscribe((data)=>{console.log(data)})     
   

      this.service.uploadfile(file).subscribe((sa: any) => {
        console.log(this.paper);
        this.service.saveAnsStudentDash({studentId: this.UserById,ansId: sa._id}).subscribe( result => {
          this.service.delQStudent({
            id: this.UserById
          }).subscribe( s =>
           { this.recording = false;
             alert('Exam Finished');
             this.router.navigate(['/result']);
           }
          );
          console.log("viewfinish",result) ;
  
        }
  
        );
        });

    } else if (this.paper.desc){

      console.log("Desc");
      
      this.service.finishDescExam({
        sheet : this.question,
        paper: this.paper,
        studentName: this.studDash.name,
        studentId: this.studId,
        studentRoll: this.studDash.Id_Roll,
        userId: this.UserById,
        descPDF: this.descPDF,
        descType: this.paper.descType,
        instruction: this.paper.instruction,
        videoFileName: this.studDash.name + this.studDash._id + this.paper._id
      }).subscribe((s:any) => {
        this.service.saveAnsStudentDash({studentId: this.UserById,ansId: s._id }).subscribe(final => {
          this.recording = false;
          
            alert('Exam Finished');
            this.router.navigate(['/result']);
          
        
           
        });
      }
      )
    }
  }
  
}

  openPaper(){
    console.log("openPass");
    const dialogRef = this.dialog.open(QuestionOverviewComponent, {height: '100%' ,width:'100%'});

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  instruct(){
    console.log("instructPass");
    const diagRef = this.dialog.open(InstructionSetComponent, {height: '100%' ,width:'100%'});

    diagRef.afterClosed().subscribe(result => {
      console.log(`Dia result: ${result}`);
    });
  }




  /*calculatHeight(d) {
    var doc = new jsPDF.jsPDF();
    let height = 80;
    
    if (d.question) {
      var splitQuestion = doc.splitTextToSize(d.question,180);
        for (let i =0; i< splitQuestion.length; i++) {
        height = height +5;
        }
    }
    /*if (d.diagram) {
      height = height +45;
    }
    if (d.option1) {
      height = height +15;
    }
    if (d.option2) {
      height = height +15;
    }
    if (d.option3) {
      height = height +15;
    }
    if (d.option4) {
      height = height +15;
    }
    if (d.imgOpt1) {
      height = height +45;
    }
    if (d.imgOpt2) {
      height = height +45;
    }
    if (d.imgOpt3) {
      height = height +45;
    }
    if (d.imgOpt4) {
      height = height +45;
    }
    if (d.imgObtMarks) {
      height = height +15;
    }
    if (d.marks) {
      height = height +15;
    }
    if (d.chosenAnswer) {
      height = height +15;
    }
    if (d.imgAnswer) {
      height = height +15;
    }
    if (d.corrAnswer) {
      height = height +15;
    }
    if (this.descPDF) {
      var splitQuestion = doc.splitTextToSize(d.descPDF,180);
        for (let i =0; i< splitQuestion.length; i++) {
        height = height +5;
        }
    }
    
  
    console.log('calH :' + height)
    return height;
  }*/
  
  
  
  downloadPdf() {
    console.log(this.paper);
    var doc = new jsPDF.jsPDF();
    let d_height = 15;
    doc.text('' + this.paper.examName, 85, d_height);
    d_height = d_height + 10;
    doc.text('Course:' + this.paper.course, 70, d_height);
    d_height = d_height + 10;
    doc.text('Subject:' + this.paper.subject, 70, d_height);
    d_height = d_height + 10;
    doc.text('SubCode:' + this.paper.subjectCode, 70, d_height);
    d_height = d_height + 10;
    for (let index = 0; index < this.descPDF.length; index++) {
      let question = this.descPDFquest[index];
       let answer = this.descPDF[index];
       var splitQuestion = doc.splitTextToSize(question,180);
      var d_cal_height=d_height + splitQuestion.length*5;
       var splitanswer = doc.splitTextToSize(answer,180);
       d_cal_height=d_cal_height + splitanswer.length*5;

      if(d_cal_height>390)
      {
        doc.addPage();
        d_height=15;
      }
      doc.text((index+1) +"."+ splitQuestion, 10, d_height);
      d_height=d_height + splitQuestion.length*5+5;
      doc.text("Answer:- "+splitanswer, 10 ,d_height);
      d_height=d_height + splitanswer.length*5 +15;
    }

    
   

    console.log("this path");
    
    doc.save('pdf');
    var blob = new Blob([doc.output('blob')], { type: "pdf"});
    this.service.uploadAnswerSingle(blob).subscribe(p => {
      console.log("uploadpdf",p.path);
      // this.question[this.i].imgAnswer = p.path;
      alert(p.path)
      this.descPDF = p.path;
      alert(p+ 'uploaded'); },
      e => {
        alert('failed');
        console.log("upload pdf 2",e) ;} )
    
  
}
  saveSub()
  {
    this.question[this.i].descriptiveAnswerInput = this.descriptiveInput;
    this.descPDFquest[this.i]=this.dataDisplay.question;
    this.downloadPdf();
    alert("Paper saved");


    
  }
  
}



  
  


