<div class="container" style="padding: 3%;">
    <h1>Edit Profile</h1>
    <hr>
    <div class="row">
        <!-- left column -->
        <div class="col-md-3">
            <div class="text-center">
                <img src="//placehold.it/100" class="avatar img-circle" alt="avatar">
                <h6>Upload a different photo...</h6>

                <input type="file" disabled>
            </div>
        </div>

        <!-- edit form column -->
        <div class="col-md-9 personal-info">
            <div class="alert alert-info alert-dismissable">
                <a class="panel-close close" data-dismiss="alert">×</a>
                <i class="fa fa-coffee"></i> This is an <strong>.alert</strong>. Use this to show important messages to the user.
            </div>
            <h3>Personal info</h3>

            <form class="form-horizontal" role="form">
                <div class="form-group">
                    <label class="col-lg-3 control-label">Name:</label>
                    <div class="col-lg-8">
                        <input class="form-control" type="text" value="{{student.name}}">
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-lg-3 control-label">Roll:</label>
                    <div class="col-lg-8">
                        <input class="form-control" type="text" value="{{student.Id_Roll}}">
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-lg-3 control-label">University-ID</label>
                    <div class="col-lg-8">
                        <input class="form-control" type="text" value="{{student.clgId}}">
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-lg-3 control-label">Email/LogIn-ID:</label>
                    <div class="col-lg-8">
                        <input class="form-control" type="text" value="{{user.email}}">
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-lg-3 control-label">Contact:</label>
                    <div class="col-lg-8">
                        <input class="form-control" [(ngModel)]="newContact" name="newContact" type="text" placeholder="{{user.contact}}">
                    </div>

                </div>

                <div class="form-group">
                    <label class="col-md-3 control-label">Password:</label>
                    <div class="col-md-8">
                        <input class="form-control" value="{{user.password}}">
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-md-3 control-label">New Password:</label>
                    <div class="col-md-8">
                        <input class="form-control" [(ngModel)]="newPass" name="newPass">
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-md-3 control-label">Confirm Password:</label>
                    <div class="col-md-8">
                        <input class="form-control" [(ngModel)]="oldPass" name="oldPass">
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-md-3 control-label"></label>
                    <div class="col-md-8">
                        <button mat-raised-button color="primary" (click)="updatePass()" [disabled]="!newPass">Update Password</button>
                        <span></span>
                        <input type="reset" class="btn btn-default" value="Cancel">
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<hr>