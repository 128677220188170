import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from 'src/app/service/REST';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  user: any;
  UserById: any;
  email: any;
  password: any;
  @Output() signedInEvent= new EventEmitter<boolean>();

  constructor( public router: Router, public service: Service) { }

  ngOnInit(): void {
  }

  signIn() {
    this.user = {
      email: this.email,
      password: this.password
    };
    this.service.signIn(this.user).subscribe((res) => {
      if (!res) {
        console.log('user not found');
      } else {
        localStorage.setItem('studentId', JSON.stringify(res) );
       // location.reload();
       // this.router.navigate(['/']);
       this.signedInEvent.emit();
      
      
      }
      // console.log(res);
     });
  }

  checkUser(userId) {
    this.service.getUserById({id: userId}).subscribe( user => {
      this.UserById  = user;
      console.log(user);
      if (this.UserById.isStudent) {

        this.router.navigate(['/studentHome']);

      } else {
        this.router.navigate(['/upload']);
      }
    });
  }

}
