<div class="container-fluid" style="padding: 5%;">
    <div class="row"><i class="fas fa-file-alt" style='margin-left: 10%;margin-right: 10px;font-size:25px;color:rgb(79, 39, 221)'></i>
        <h2>Answers</h2>
    </div>
    <table class="table">
        <thead class="thead-purple "  style="text-align: center;  ">
        
            <tr >
                <th scope="col">#</th>
                <th scope="col">Exam Name</th>
                <th scope="col">Course</th>
                <th scope="col">Stream</th>
                <th scope="col">Name/Year</th>
                <th scope="col">Semester</th>
                <th scope="col">Subject</th>
                <th scope="col">Total Marks</th>
                <th scope="col">Score</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
                <th scope="col">Download</th>


            </tr>
         
        
        </thead>
        <tbody *ngFor="let a of ans">
            <tr *ngIf="a.checked" style="text-align: center;">
                <th scope="row">{{ ans.indexOf(a) + 1 }}</th>
                <td>{{ a.examName}}</td>
                <td>{{ a.course}}</td>
                <td>{{ a.stream}}</td>
                <td>{{ a.year}}</td>

                <td>{{ a.sem}}</td>
                <td>{{ a.subject}}</td>
                <td>{{ a.totalMarks}}</td>
                <td>{{ a.obtMarks}} / {{ a.totalMarks}}</td>
                <td>{{ a.date | date}}</td>
                <td><button mat-raised-button color="primary" (click)="viewResult(a)">View Results</button></td>
                <td>
                    <i class='fas fa-file-image' (click)="downloadPdf(a)" style='cursor: pointer;margin-left: 10%;margin-right: 10px;font-size:25px;color:rgb(223, 59, 48)'></i>
                </td>
            </tr>
        </tbody>
    </table>
</div>