<div class="card-container">
    <div class="row"><i class="fas fa-user-alt" style='margin-left: 5%;margin-right: 10px;font-size:30px;color:rgb(79, 39, 221)'></i>
        <h2>Profile</h2>
    </div>
    <div class="upper-container">
        <div class="image-container">
            <img src="assets/images/pro.png">
        </div>

    </div>



    <div class="lower-container">

        <div>


            <div class="container">

                <table class="table">

                    <thead>
                        <tr>
                            <th>Name </th>
                            <td>{{ student.name }}</td>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <th>Password </th>
                            <td>{{ user.password}}</td>
                        </tr>
                        <tr>
                            <th>Mobile: </th>
                            <td>{{ user.contact }}</td>
                        </tr>
                        <tr>
                            <th>Email: </th>
                            <td>{{ student.email }}</td>
                        </tr>
                        <tr>
                            <th>Roll: </th>
                            <td>{{ student.Id_Roll }}</td>
                        </tr>
                        <tr>
                            <th>Institute ID </th>
                            <td>{{ student.clgId }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>



    </div>
</div>
<div class="button">
    <a [routerLink]="[ '/edit' ]" class="btn">Edit profile</a>
</div>