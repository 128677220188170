import { Component, OnInit } from '@angular/core';
import { Service } from 'src/app/service/REST';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-result-sheet',
  templateUrl: './result-sheet.component.html',
  styleUrls: ['./result-sheet.component.css']
})
export class ResultSheetComponent implements OnInit {
  answers = [];

  constructor(public service: Service) { }

  ngOnInit(): void {
    this.answers = this.service.answerSheet;
    console.log("checked_answersheet>");
    console.log(this.answers);
    
  }

  downloadPdf(pdf) {
    // console.log(pdf)
    this.service.downloadPdfAnswer({file: pdf}).subscribe(s => {
      console.log(s);
      saveAs(s, pdf);
      // saveAs(s,pdf);
     })
  }

}
