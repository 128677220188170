<!--<app-video-recording *ngIf="recording">
</app-video-recording>-->
<div class="bgCover">
    <table class="table">
        <tbody>
            <tr>
                <th>SUBJECT:</th>
                <td>{{paper.subject}}</td>
            </tr>

            <tr>
                <th>COURSE:</th>
                <td>{{paper.course}}</td>
            </tr>

            <tr>
                <th>STREAM:</th>
                <td>{{paper.stream}}</td>
            </tr>

            <tr>
                <th>SEMESTER:</th>
                <td>{{paper.sem}}</td>
            </tr>

            <tr>
                <th>TOTAL MARKS: </th>
                <td>{{paper.totalMarks}}</td>
            </tr>

            <tr>
                <th>TIME:</th>
                <td>{{paper.time}}</td>
            </tr>
            <tr *ngIf="paper.descType === 'pdf'">
                <th>
                    <mat-label>Upload PDF</mat-label>
                </th>
                <td><input style="width: 250px;" type="file" placeholder="Answer" (change)=uploadPdf($event)></td>
            </tr>
        </tbody>
    </table>



    <!--

    <h4>{{ paper.examName }}</h4>
    <h4>Subject: {{ paper.subject }} {{ paper.subjectCode }}</h4>
    <h4>Course: {{ paper.course }}</h4>
    <h4>Stream: {{ paper.stream }}</h4>
    <h4>Semester: {{ paper.sem }}</h4>
    <h4>Total Marks: {{ paper.totalMarks}}</h4>
    <h5>{{ timer }} mins remaining</h5>
    <div class="col-md-6" *ngIf="paper.descType === 'pdf'">
        <mat-label>Upload PDF</mat-label>
        <input type="file" placeholder="Answer" (change)=uploadPdf($event)>
    </div>
    -->

    <button (click)="finish()" style="margin: 5px; padding: 5px ; " type="submit" mat-raised-button color="accent" >FINISH EXAM</button>

</div>
<h2 class="title" style="padding: 5px; font-weight: bolder; color:rgb(31, 78, 167); border-bottom: 5px solid rgb(10, 67, 173);">Question summary</h2>
<div class="container-fluid row" style="background-color: rgb(255, 255, 255);padding: 3%;">


    <div class="col-md-8" style="margin: 10px;">
        <div class="overview">
            <button mat-raised-button style="padding: 5px;" color="primary" (click)="openPaper()">PAPER OVERVIEW</button>
            <button style="float: right;padding: 5px;" mat-raised-button color="primary" (click)="instruct()">INSTRUCTION</button>
        </div>
    </div>
    <div class="col-md-8" style="margin: 10px;">

        <ul role="list" class="list-group" style="margin-top: 5px; list-style-type: none;border:1px solid rgb(187, 187, 187); box-shadow: 0px 3px 12px 0px rgba(109, 109, 109, 0.904);">
            <li class="list-group-item" style="min-width:fit-content;padding:3%;color: rgb(14, 92, 182);font-weight: bolder" role="listitem">Q{{i+1}}: {{dataDisplay.question }} ( marks: {{ dataDisplay.marks }})</li>
        </ul>
    </div>
    <div class="col-md-8" style="margin: 10px;">
        <h2 class="title" style="padding: 5px; font-weight: bolder; color:rgb(31, 78, 167); ">Answer</h2>
        <ul role="list" class="list-group" style="margin-top: 5px; list-style-type: none;border:1px solid rgb(187, 187, 187); box-shadow: 0px 3px 12px 0px rgba(109, 109, 109, 0.904);">
           
                
        <div>
       <!-- <div *ngIf="paper.testPapers[this.i].file">-->
            <li *ngIf="paper.testPapers[this.i].file" style="height: 350px;" class="list-group-item" style="height: 450px;border-bottom: 1px solid rgb(172, 172, 172);">
                Diagram:
                <img class="diagram" src="https://eiilmlibrary.com:8080/{{paper.testPapers[this.i].file}}">
            </li>
            <li style="height: 100px;" class="list-group-item" *ngIf="dataDisplay.option1!=null" role="listitem">
                  <input [(ngModel)]="dataDisplay.imgAnswer" type="radio" value="{{1}}" name="answer">

                    (i) {{[dataDisplay.option1]}}
                 </li>

            <li class="list-group-item" *ngIf="paper.testPapers[this.i].imgOpt1" class="diagram">
                    (i)<img class="diagram" src="https://eiilmlibrary.com:8080/{{paper.testPapers[this.i].imgOpt1}}">
            </li>
            <li style="height: 100px;" class="list-group-item" *ngIf="dataDisplay.option2!=null" role="listitem">
                <input [(ngModel)]="dataDisplay.imgAnswer" type="radio" value="{{2}}" name="answer">(ii)  {{[dataDisplay.option2]}}
            </li>
            <li class="list-group-item" *ngIf="paper.testPapers[this.i].imgOpt2" class="diagram" role="listitem">
                    (ii)<img class="diagram" src="https://eiilmlibrary.com:8080/{{paper.testPapers[this.i].imgOpt2}}">
            </li>
            <li style="height: 100px;" class="list-group-item" *ngIf="dataDisplay.option3!=null" role="listitem">
                <input [(ngModel)]="dataDisplay.imgAnswer" type="radio" value="{{3}}" name="answer">(iii)  {{[dataDisplay.option3]}}
            </li>
            <li class="list-group-item" *ngIf="paper.testPapers[this.i].imgOpt3" class="diagram" role="listitem">     
                    (iii)<img class="diagram" src="https://eiilmlibrary.com:8080/{{paper.testPapers[this.i].imgOpt3}}">
            </li>
            <li style="height: 100px;" class="list-group-item" *ngIf="dataDisplay.option4!=null" role="listitem">
                <input [(ngModel)]="dataDisplay.imgAnswer" type="radio" value="{{4}}" name="answer">(iv) {{[dataDisplay.option4]}}
            </li>
            <li class="list-group-item" *ngIf="paper.testPapers[this.i].imgOpt4" class="diagram" role="listitem">                
                    (iv)<img class="diagram" src="https://eiilmlibrary.com:8080/{{paper.testPapers[this.i].imgOpt4}}">
            </li>
            <li class="list-group-item">
               
            </li>
        </div>
        </ul>
    <div class="row" *ngIf="paper.descType === 'input'" style="padding:5%;">
    
        
    
    <textarea id="w3review" [(ngModel)]="descriptiveInput" rows="4" wrap="hard"   cols="50" >
        </textarea>
        
        </div>

        <div class="row" style="padding:5%;">
            <div class="col-lg-6">
                <button style="margin: 2px;" [disabled]="i<=0" type="submit" mat-raised-button color="primary" (click)="prev()">Previous</button> &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div class="col-lg-6"> 
                <button *ngIf="i<((paper.testPapers.length)-1)" style="margin: 2px; float: right;" type="submit" mat-raised-button color="primary" (click)="next()">Next &raquo;</button>
                <!--<button *ngIf="i==((paper.testPapers.length)-1)" style="margin: 2px; float: right;" type="submit" mat-raised-button color="primary" (click)="saveSub()">Save & Finish &raquo;</button>-->
            </div>
        </div>
          

    </div>
    <div class="col-md-2" style="margin: 10px">
        <h2 class="title" style="padding: 5px; font-weight: bolder; color:rgb(31, 78, 167); border-bottom: 5px solid rgb(10, 67, 173);">Question Select</h2>
        <div class="grid-container">
            <div style="border: 1px solid red;border-radius:25px ;height: 35px; padding: 5%;text-align: center;" [ngClass]="(paper.testPapers[paper.testPapers.indexOf(q)].imgAnswer!=null)?'answered':'item'" (click)="goto(paper.testPapers.indexOf(q))" *ngFor="let q of paper.testPapers">
                {{paper.testPapers.indexOf(q) + 1}}
            </div>
        </div>
    </div>
</div>