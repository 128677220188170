import { Component, OnInit } from '@angular/core';
import { Service } from 'src/app/service/REST';

@Component({
  selector: 'app-student-home',
  templateUrl: './student-home.component.html',
  styleUrls: ['./student-home.component.css']
})
export class StudentHomeComponent implements OnInit {
  UserById = false;

  constructor(public service: Service) { }

  ngOnInit(): void {
    this.checkToken();
  }
  checkToken() {
    if (! (JSON.parse(localStorage.getItem('studentId')))) {
      this.UserById = false;

    } else { this.UserById = true; }
  }


}
