<div class="container-fluid" style="height:100%;background-color: rgb(89, 78, 233); padding-top: 15%;padding-bottom: 15%;">
    <mat-card style="width: 350px;float: center;margin-left: 25%;margin-right: 25%;padding: 3%;">
        <h3 style="text-align: center;color: rgb(57, 73, 219);">SIGN IN</h3>

        <mat-form-field appearance="fill" style="width:100%;">
            <mat-label>ID or email</mat-label>
            <input matInput [(ngModel)]="email" name="email">

        </mat-form-field>
        <br>
        <mat-form-field appearance="fill" style="width:100%;">
            <mat-label>PASSWORD</mat-label>
            <input matInput [(ngModel)]="password" name="password" type="password">

        </mat-form-field>
        <br>
        <button mat-raised-button color="accent" style="margin-top: 5px;margin-bottom: 5px;width: 100%;" (click)="signIn()">SIGN IN</button>

    </mat-card>
</div>