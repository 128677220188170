import { Component, OnInit } from '@angular/core';
import { Service } from 'src/app/service/REST';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  student: any = "";
  UserById: any = "";
  user: any = "";

  constructor(public service: Service) { }

  ngOnInit(): void {
    this.checkToken();
  }
  checkToken() {
    if (! (JSON.parse(localStorage.getItem('studentId')))) {
      alert('student not logged in');
      // this.UserById = JSON.parse(localStorage.getItem('studentId'));

    } else {
      this.UserById = JSON.parse(localStorage.getItem('studentId'));
      // tslint:disable-next-line:max-line-length
      this.service.getStudDash({id: this.UserById}).subscribe((s: any) => {
        this.student = s;
        this.service.getUserById({id: this.UserById }).subscribe(s => {
          this.user = s
        });
        
        });

  }
  }

}
