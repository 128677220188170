import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule  } from '@angular/common/http' ;

@Injectable()
export class Service {
    qustionSet: any[];
    viewPaper: any;
    examSheet: any;
    answerSheet: any;
    LiveQuestionSet: any =  [];
    port = 'https://eiilmlibrary.com:8080';
    // port = 'https://mockt-backend.herokuapp.com';
    // port = 'https://server.eiilm.co.in:3000';
    // port = 'http://localhost:3000';
    constructor(public http: HttpClient) {}

     // AUTHENTICATION
     signIn(user) {
        return this.http.post(this.port + '/student/signIn', user);
    }

    updatePasssword(user) {
        return this.http.post(this.port + '/student/upPass', user);
    }
    updateContact(user) {
        return this.http.post(this.port + '/student/upContact', user);
    }


    getUsers() {
        return this.http.get(this.port + '/getUsers');
    }

    getUserById(id) {
        return this.http.post(this.port + '/getUserById', id);
    }

    // student
    createDashBoardforStudent(set) {
        return this.http.post(this.port + '/getNewDashForStudent', set);
    }
    getstudentDashbyId(id) {
        return this.http.post(this.port + '/getStudentDashById', id);
    }

    getPreviousTests() {
        return this.http.get(this.port + '/getPreviousTests');
    }

    getTestPaperById(set) {
        return this.http.post(this.port + '/getTestBankModeratorById', set);
    }
    startExam(set) {
        return this.http.post(this.port + '/Student/startExam', set);
    }

    startExamById(set) {
        return this.http.post(this.port + '/Student/startExamById', set);
    }


    getStudDash(set) {
        return this.http.post(this.port + '/Student/getStudentDashById', set);
    }
    finishExam(set) {
        return this.http.post(this.port + '/Student/marksCalStudent', set);
    }
    finishDescExam(set) {
        console.log("service set");
        console.log(set);
        return this.http.post(this.port + '/Student/submitAnswerDesc', set);
    }
    finishExamTest(set) {
        return this.http.post(this.port + '/Student/marksCalStudentTest', set);
    }
    delQStudent(id) {
        return this.http.post(this.port + '/Student/delQuestions', id);
    }
    getAns() {
        return this.http.get(this.port + '/Student/getAnswer');
    }
    saveAnsStudentDash(set) {
        return this.http.post(this.port + '/Student/saveResult', set);
    }
    getAnsBystud(id) {
        return this.http.post(this.port + '/Student/getAnswerByStud', id);
    }
    getAnsBystud2(id) {
        return this.http.post(this.port + '/Student/getAnswerByStud2', id);
    }
    timerUpdate(req) {
        return this.http.post(this.port + '/Student/timerUpdate', req);
    }
    getLiveMock(set)  {
        //return this.http.post(this.port + '/Student/getLiveMock', set);
        return this.http.post(this.port + '/Student/getLiveMockBySubCode', set);
    }
    copyVideo(set) {
        return this.http.post(this.port + '/admin/copyVideo', set)
    }


    uploadAnswerSingle(pdf) {
        const formData = new FormData();
        formData.append('upAns', pdf, '.pdf');
        return this.http.post<any>(this.port + '/Student/upAns', formData);
    }

    downloadPdfAnswer(file) {
        var body = {filename:file}
        return this.http.post(this.port + '/admin/downloadImgAns',  body, {
            responseType : 'blob',
            headers: new HttpHeaders().append('Content-Type', 'application/json')
        });
    }

    uploadfile(file){
        console.log("File upload trigger");
        const formData = new FormData();
        formData.append('upAns', file, '.txt');
        return this.http.post<any>(this.port + '/Student/tempUploadAnswer', formData);

    }

    
}
