<!--<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
        <mat-toolbar>Menu</mat-toolbar>
        <mat-nav-list>
            <a style="color: antiquewhite;" mat-list-item [routerLink]="['/']" routerLinkActive="router-link-active">Tests</a>

        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="primary">
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
            <span>EXPERT PROFESSIONAL</span>
        </mat-toolbar>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container> -->
<mat-sidenav-container fullscreen>
    <mat-sidenav class="sidenav" mode="side" #sidenav opened position="start" style="background-color: rgb(23, 23, 104);">
        <mat-toolbar style="background-color: rgb(65, 65, 120); color: cornsilk;">Menu</mat-toolbar>
        <mat-nav-list>
            <a style="color: antiquewhite;" mat-list-item [routerLink]="['/Tests']" routerLinkActive="router-link-active">Tests</a>
            <a style="color: antiquewhite;" mat-list-item [routerLink]="['/result']" routerLinkActive="router-link-active">Results</a>


        </mat-nav-list>

    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="primary">
            <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button> EXPERT PROFESSIONALS
            <span class="toolbar-spacer"></span><button mat-icon-button [matMenuTriggerFor]="set" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
            <mat-menu #set="matMenu">
                <button mat-menu-item style="text-decoration: none;">
                  <mat-icon>account_circle</mat-icon>
                  <a [routerLink]="['/']">My Profile</a>
                </button>
                <button mat-menu-item style="text-decoration: none;">
                  <mat-icon>settings</mat-icon>
                  <a [routerLink]="['/edit']">Settings</a>
                </button>
                <button mat-menu-item (click)="logOut()" style="text-decoration: none;">
                  <mat-icon>exit_to_app</mat-icon>
                  <span>Log Out</span>
                </button>
            </mat-menu>
        </mat-toolbar>
        <div class="main-content">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>