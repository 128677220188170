import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StudentTestsComponent } from './student/student-tests/student-tests.component';
import { StudentViewPaperComponent } from './student/student-view-paper/student-view-paper.component';
import { ResultComponent } from './student/result/result.component';
import { ResultSheetComponent } from './student/result-sheet/result-sheet.component';
import { ProfileComponent } from './student/profile/profile.component';
import { EditComponent } from './student/profile/edit/edit.component';
import { InstructionSetComponent } from './student/student-view-paper/instruction-set/instruction-set.component';
import { QuestionOverviewComponent } from './student/student-view-paper/question-overview/question-overview.component';


const routes: Routes = [
  { path: 'Tests' , component: StudentTestsComponent },
  { path: 'viewPaper' , component: StudentViewPaperComponent},
  { path: 'result', component: ResultComponent},
  { path: 'viewResult' , component: ResultSheetComponent },
  { path: '' , component: ProfileComponent },
  { path: 'edit' , component: EditComponent },
  { path: 'instructionSet', component: InstructionSetComponent },
  { path: 'questionOverView', component: QuestionOverviewComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingcomponents = [ ResultComponent , StudentTestsComponent, StudentViewPaperComponent ];
