import { Component, OnInit } from '@angular/core';
import { Service } from 'src/app/service/REST';
import { Router } from '@angular/router';
import * as jsPDF from 'jspdf';
@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {
  ans: any = [];
  UserById: any;
  studDash: any = [];
  studId: any;
  testResults: any = [];
  paper: any = [];
  question: any;

  constructor(public service: Service, public router: Router) { }

  ngOnInit(): void {
    this.checkToken();

  }


  checkToken() {
    if (! (JSON.parse(localStorage.getItem('studentId')))) {
      this.UserById = JSON.parse(localStorage.getItem('studentId'));

    } else {
      this.UserById = JSON.parse(localStorage.getItem('studentId'));
      // tslint:disable-next-line:max-line-length
      this.service.getStudDash({id: this.UserById}).subscribe((s: any) => { this.studDash = s; this.studId = s._id; this.testResults = s.testResults; this.getAns(s.testResults);  });
  }
  }

  getAns(testResults) {
    console.log(testResults);

    for (const r of testResults) {
      this.service.getAnsBystud({ansId: r}).subscribe(ans => {
        if(ans) {
          this.ans.push(ans);
          console.log("hello" );
          console.log( this.ans);
        }
        
       });
    }

  }
  viewResult(paper) {
    this.service.answerSheet = paper.answers;
    this.paper = this.service.viewPaper;
    //this.question = this.service.viewPaper.testPapers;
    
    console.log("normal Paper");
    console.log(paper);
    console.log("Called Paper");
    console.log(this.paper);
    //console.log("Question Paper");
    //console.log(this.question);
    this.router.navigate(['/viewResult']);
  }

  calculatHeight(d) {
    var doc = new jsPDF.jsPDF();
    let height = 80;
    
    if (d.question) {
      var splitQuestion = doc.splitTextToSize(d.question,180);
        for (let i =0; i< splitQuestion.length; i++) {
        height = height +5;
        }
    }
    if (d.diagram) {
      height = height +45;
    }
    if (d.option1) {
      height = height +15;
    }
    if (d.option2) {
      height = height +15;
    }
    if (d.option3) {
      height = height +15;
    }
    if (d.option4) {
      height = height +15;
    }
    if (d.imgOpt1) {
      height = height +45;
    }
    if (d.imgOpt2) {
      height = height +45;
    }
    if (d.imgOpt3) {
      height = height +45;
    }
    if (d.imgOpt4) {
      height = height +45;
    }
    if (d.imgObtMarks) {
      height = height +45;
    }
    if (d.marks) {
      height = height +45;
    }
    if (d.chosenAnswer) {
      height = height +45;
    }
    if (d.corrAnswer) {
      height = height +45;
    }

    console.log('calH :' + height)
    return height;
  }



  downloadPdf(paper) {
    var doc = new jsPDF.jsPDF();
    let d_height = 15;
    doc.text('' + paper.examName, 85, d_height);
    d_height = d_height + 10;
    doc.text('Course:' + paper.course, 70, d_height);
    d_height = d_height + 10;
    doc.text('Subject:' + paper.subject, 70, d_height);
    d_height = d_height + 10;
    doc.text('SubCode:' + paper.subjectCode, 70, d_height);
    d_height = d_height + 10;
    doc.text('TotalMarks:' + paper.totalMarks, 70, d_height);
    d_height = d_height + 10;
    doc.text('ObtainedMarks:' + paper.obtMarks, 70, d_height);
    d_height = d_height + 25;
    
    
    // doc.setFontSize(10);
    for (const d of paper.answers) {
    let q_height =  this.calculatHeight(d);
    console.log(q_height + d_height);
    if (q_height + d_height > 390) {
      
      doc.addPage();
      d_height = 15;
    }

 
    if (d.diagram) {
      const img  = 'https://expertprofessionals.org:8080/'+d.diagram;
      doc.addImage(img, 'png', 20, d_height , 40, 40 );
      d_height = d_height + 40 + 5;
    }
    var splitQuestion = doc.splitTextToSize(d.question,180);
      for (let i =0; i< splitQuestion.length; i++) {
        if (i === 0) {
          doc.text((paper.answers.indexOf(d)+ 1) + '. ' + splitQuestion[i], 10, d_height); 
          d_height = d_height + 8;
        } else {
          doc.text(splitQuestion[i], 10, d_height);
          d_height = d_height + 8;
        }
       
      }

      if (d.imgOpt1) {
        doc.text('[i]', 10,d_height );
        const img  = 'https://expertprofessionals.org:8080/'+d.imgOpt1;
        doc.addImage(img, 'png', 20, d_height , 40, 40 );
        d_height = d_height + 40 + 5;
      } else if (d.option1) {
        doc.text('[i]' + d.option1, 10, d_height);
        d_height = d_height + 15;
      }
      
      if (d.imgOpt2) {
        doc.text('[ii]', 10,d_height );
        const img  = 'https://expertprofessionals.org:8080/'+d.imgOpt2;
        doc.addImage(img, 'png', 20, d_height , 40, 40 );
        d_height = d_height + 40 + 5;
      } else if (d.option2) {
        doc.text('[ii]' + d.option2, 10,  d_height);
        d_height = d_height + 15;
      } 
      if (d.imgOpt3) {
        doc.text('[iii]', 10,d_height );
        const img  = 'https://expertprofessionals.org:8080/'+d.imgOpt3;
        doc.addImage(img, 'png', 20, d_height , 40, 40 );
        d_height = d_height + 40 + 5;
      } else if (d.option3) {
        doc.text('[iii]' + d.option3, 10,  d_height);
        d_height = d_height + 15;
      }
      if (d.imgOpt4) {
        doc.text('[iv]', 10,d_height );
        const img  = 'https://expertprofessionals.org:8080/'+d.imgOpt4;
        doc.addImage(img, 'png', 20, d_height , 40, 40 );
        d_height = d_height + 40 + 5;
      } else if (d.option4) {
        doc.text('[iv]' + d.option4, 10,  d_height);
        d_height = d_height + 15;
      }
      if (d.marks) {
        doc.text('Marks:' + d.marks, 10, d_height);
        d_height = d_height + 15;
      }
      if (d.imgObtMarks) {
        doc.text('Obtained marks:' + d.imgObtMarks, 10, d_height);
        d_height = d_height + 15;
      }
      if (d.chosenAnswer) {
        doc.text('Chosen Answer:' + d.chosenAnswer, 10, d_height);
        d_height = d_height + 15;
      }
      if (d.corrAnswer) {
        doc.text('Correct Answer:' + d.corrAnswer, 10, d_height);
        d_height = d_height + 15;
      }
               
     }    
    doc.save('pdf')
  }
}
