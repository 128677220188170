import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StudentHomeComponent } from './student/student-home/student-home.component';
import { StudentTestsComponent } from './student/student-tests/student-tests.component';
import { StudentViewPaperComponent } from './student/student-view-paper/student-view-paper.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { CreateAccountComponent } from './auth/create-account/create-account.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import {MatTableModule, MatTable} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import {MatCardModule} from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { HttpClientModule } from '@angular/common/http';
import { Service } from './service/REST';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MaterialModule } from './material.module';
import {MatMenuModule} from '@angular/material/menu';
import { ResultComponent } from './student/result/result.component';
import { ResultSheetComponent } from './student/result-sheet/result-sheet.component';
//import { VideoRecordingComponent } from './student/student-view-paper/video-recording/video-recording.component';
import { ProfileComponent } from './student/profile/profile.component';
import "@angular/compiler";
import { CommonModule } from '@angular/common';
import { EditComponent } from './student/profile/edit/edit.component';
import { QuestionOverviewComponent } from './student/student-view-paper/question-overview/question-overview.component';
import { InstructionSetComponent } from './student/student-view-paper/instruction-set/instruction-set.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import {ReactiveFormsModule} from '@angular/forms';





@NgModule({
  declarations: [
    AppComponent,
    StudentHomeComponent,
    StudentTestsComponent,
    StudentViewPaperComponent,
    SignInComponent,
    CreateAccountComponent,
    MainNavComponent,
    ResultComponent,
    ResultSheetComponent,
    
    //VideoRecordingComponent,
    
    ProfileComponent,
    EditComponent,
    QuestionOverviewComponent,
    InstructionSetComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,

    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    MaterialModule,
    MatTableModule,
    MatDividerModule,
    MatCardModule,
    MatCheckboxModule,
    MatMenuModule,MatRadioModule
  ],
  providers: [Service, MatSelectModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
