<div class="container-field" style="padding: 5%;">
    <h2 style="margin-bottom: 5%;">ONGOING MOCK TESTS</h2>
    <table class="table  table-striped table-bordered table-darrk" style="width: 100%;">
        <thead class="table-primary">
            <tr>


                <th scope="col">Exam-Name</th>
                <th scope="col">Total Marks</th>
                <th scope="col">Course</th>
                <th scope="col">Subject</th>
                <th scope="col">Year</th>
                <th scope="col">Timer</th>
                <th scope="col">Semester</th>
                <th scope="col">ACTION</th>
            </tr>
        </thead>
        <tbody *ngFor="let paper of ELEMENT_DATA">
            <tr *ngIf="paper.LIVE">


                <td>{{ paper.examName }}</td>
                <td>{{ paper.totalMarks}}</td>
                <td>{{ paper.course }}</td>
                <td>{{ paper.subject }}</td>
                <td>{{ paper.year }}</td>
                <td>{{ paper.timer }}</td>
                <td>{{ paper.sem }}</td>
                <td>
                    <button mat-raised-button color="primary" (click)="startExam(paper)" *ngIf="studDash.liveTestId != paper.testID; else resume">START EXAM</button>
                    <ng-template #resume>
                        <button mat-raised-button color="accent" (click)="resumeExam(paper)">RESUME EXAM</button>
                    </ng-template>

                </td>
            </tr>

        </tbody>
    </table>
</div>