import { Component, OnInit } from '@angular/core';
import { Service } from 'src/app/service/REST';
import { Router } from '@angular/router';

@Component({
  selector: 'app-student-tests',
  templateUrl: './student-tests.component.html',
  styleUrls: ['./student-tests.component.css']
})
export class StudentTestsComponent implements OnInit {
  ELEMENT_DATA: any = [];
  UserById: any;
  studDash: any;
  studId: any;
  url = 'https://eiilmlibrary.com:8080/';
  vExam: any = [];

  constructor(public service: Service, public router: Router) { }

  ngOnInit(): void {
    console.log(this.url);
    this.checkToken();
    // this.getResult();

  }
  checkCamera() {
    /*navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
          width: { ideal: 1280 },
          height: { ideal: 720 }
      }
  }).then((m) => { console.log('Camera Available'); console.log(this.url); }).catch((e) => { console.log('Camera not found'); });*/

  
}

subjectCodeArray:any=[];

  checkToken() {
    if (! (JSON.parse(localStorage.getItem('studentId')))) {
      alert('student not logged in');
      localStorage.removeItem('studentId');
      window.location.reload();
      
      // this.UserById = JSON.parse(localStorage.getItem('studentId'));

    } else {
      this.UserById = JSON.parse(localStorage.getItem('studentId'));
      // tslint:disable-next-line:max-line-length

      this.studDash=this.service.getstudentDashbyId({id:this.UserById});
      this.service.getLiveMock({
        id:this.UserById,
   
      }).subscribe( (set: any) => {
  
      this.ELEMENT_DATA=set
      console.log(set);
  
      });
      console.log("Element_data")
      console.log(this.ELEMENT_DATA);
    }
  }







   // OLD
  getAllExams(subjects) {
    this.service.getPreviousTests().subscribe(set => {
      // this.ELEMENT_DATA = set;
      // console.log(set);
      this.checkSubjectAndCourse(set, subjects);


      // console.log(this.ELEMENT_DATA);
    });
    console.log("all Element_data")
    console.log(this.ELEMENT_DATA);
  }
  checkSubjectAndCourse(exams, student){
    const uExam = [];
    // tslint:disable-next-line:prefer-for-of
    for (let e = 0; e < exams.length; e++) {
      // tslint:disable-next-line:prefer-for-of
      for (let s = 0; s < this.studDash.subjects.length;  s++ ) {
        // tslint:disable-next-line:max-line-length
        if (this.studDash.subjects[s].name.toLowerCase() ===  exams[e].subject.toLowerCase() && student.semester === exams[e].sem && student.year.toLowerCase() === exams[e].year.toLowerCase() && student.course === exams[e].course) {
          // uExam.push(exams[e]);
          // this.ELEMENT_DATA = uExam;
          // this.checkApperance(exams[e], student);
          this.service.getAnsBystud2({Id: student._id}).subscribe((answers: any) => {
            if (answers.length === 0) {
               this.vExam.push(exams[e]);
               this.ELEMENT_DATA = this.vExam;
            } else {
              // this.vExam.splice(0, this.vExam.length);
              // tslint:disable-next-line:prefer-for-of
              for (let a = 0; a < answers.length; a++) {
                if (exams[e].testID === answers[a].mockId) {
                  // this.vExam.pop(exams[e]);
                  a = answers.length;


                } else {
                  if (a === answers.length - 1){
                    this.vExam.push(exams[e]);
                    this.ELEMENT_DATA = this.vExam;
                    console.log(this.ELEMENT_DATA);
                  }



                }

              }

            }
            // tslint:disable-next-line:prefer-for-of

          });

        }
      }
    }
    console.log("check course Element_data")
    console.log(this.ELEMENT_DATA);
  }

  checkApperance(exam, student) {
    const uExam = [];
    this.service.getAnsBystud2({Id: student._id}).subscribe((answers: any) => {
      // tslint:disable-next-line:prefer-for-of
      for (let a = 0; a < answers.length; a++) {
        if (answers[a].mockId === exam.testID) {
          console.log(exam);

        } else {
          console.log(exam);
          this.vExam.push(exam);
          this.ELEMENT_DATA = this.vExam;
        }

      }
    });
    // tslint:disable-next-line:prefer-for-of
    console.log("Element_data ca")
     console.log(this.ELEMENT_DATA);

  }






  viewPaper(set) {
    this.service.viewPaper = set;
    this.router.navigate(['/viewPaper']);
  }


  startExam(testPaper) {
    console.log("testpaper",testPaper);
    console.log("First pass");

    /*navigator.mediaDevices.getUserMedia({
      audio: true,
      /*video: {
          width: { ideal: 1280 },
          height: { ideal: 720 }
      }
  }).then((m) => {*/ 
  
      console.log({studentId:this.UserById,testId: testPaper.testID});
      this.service.startExamById({studentId:this.UserById,testId: testPaper.testID}).subscribe(test => {
      this.service.viewPaper = test;

console.log("First pass",test)

      if (!test) {
        alert('something went wrong');
      } else {


        setTimeout(() => {
          // tslint:disable-next-line:max-line-length
          console.log(this.service.viewPaper.testPapers);
          this.service.startExam({
              testId: this.service.viewPaper._id, 
              testPaper: this.service.viewPaper.testPapers, 
              id: this.UserById, 
              timer: testPaper.timer, 
              link: this.url + testPaper._id
             })
             
             .subscribe((s: any) => { this.router.navigate(['/viewPaper']); } );

           }, 300);
      }


    });//// })//.catch((e) => { alert('Camera not found'); });
  }

  resumeExam(testPaper) {
    this.service.getTestPaperById({id: testPaper.testID}).subscribe(test => {
      this.service.viewPaper = test;
      this.router.navigate(['/viewPaper']); });

  }



}
