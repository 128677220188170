import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from 'src/app/service/REST';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {

  student: any = "";
  UserById: any = "";
  user: any = "";
  newPass: string;
  oldPass: string;
  newContact: string;

  constructor(public service: Service, public router: Router) { }

  ngOnInit(): void {
    this.checkToken();
  }

  checkToken() {
    if (! (JSON.parse(localStorage.getItem('studentId')))) {
      alert('student not logged in');
      // this.UserById = JSON.parse(localStorage.getItem('studentId'));

    } else {
      this.UserById = JSON.parse(localStorage.getItem('studentId'));
      // tslint:disable-next-line:max-line-length
      this.service.getUserById({id: this.UserById }).subscribe(s => {
        this.user = s
      });
      this.service.getStudDash({id: this.UserById}).subscribe((s: any) => {
        this.student = s;
        });

  }
  }

  updatePass() {
    if (this.newPass === this.oldPass ) {
      this.service.updatePasssword({email: this.user.email, password:this.user.password, newPassword:this.newPass}).subscribe(s  => {
        alert('Password Updated');
        this.router.navigate(['/']);
      });
    } else {
      alert('Passwords does not Match');
    }
   
  }

  updateContact() {
    
      this.service.updateContact({email: this.user.email, password:this.user.password, newContact:this.newContact}).subscribe(s  => {
        alert('Contact Updated');
        this.router.navigate(['/']);
      },
      er => { alert('Something Went Wrong')});
   
   
  }


}
