import { Component, OnInit } from '@angular/core';
import { Service } from 'src/app/service/REST';

@Component({
  selector: 'app-question-overview',
  templateUrl: './question-overview.component.html',
  styleUrls: ['./question-overview.component.css']
})
export class QuestionOverviewComponent implements OnInit {
  paper: any;
  live = false;
  i = 0;
  question: any;

  constructor(public service: Service) { }

  ngOnInit(): void {
    this.ViewPaper();
  }

  ViewPaper() {
    this.paper = this.service.viewPaper;
    this.question = this.service.viewPaper.testPapers;
   // this.dataDisplay = this.question;
    //  this.i+1;
    console.log("Question overview");
     console.log(this.question);
    }

}
