<div class="container-fluid" style="padding: 5px;">
    <h1 mat-dialog-title>
        EXAM INSTRUCTIONS</h1>

    <mat-dialog-content>
        <p class="inst">{{ paper.instruction }}</p>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <i class="fa" mat-dialog-close style="cursor: pointer; font-size:20px; color: rgb(68, 68, 68);margin-right:8px;margin-left: 8px;">&#xf00d;</i>

    </mat-dialog-actions>
</div>